<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card v-b-toggle.collapse-1>
          <h3>Como importar a lista de medicamentos?</h3>
        </b-card>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <b-card-text>
              <p>1° Passo: Baixe a lista de medicamentos do site da anvisa: <a target="_blank" href="https://www.gov.br/anvisa/pt-br/setorregulado/regularizacao/medicamentos/medicamentos-de-referencia/lista-de-medicamentos-de-referencia">ACESSAR</a></p>
              <p>2° Passo: Converta o PDF em Excel com algum site on line: <a target="_blank" href="https://www.ilovepdf.com/pt/pdf_para_excel">PDF para EXCEL</a></p>
              <p>3° Passo: Exclua as colunas desnecessárias deixando apenas (FARMACO, MEDICAMENTO, CONCENTRACAO E FARMACEUTICA)</p>
              <p>4° Passo: Clique no botão "baixar modelo" para baixar o arquivo modelo, que será importado depois</p>
              <p>5° Passo: Copie apenas os dados (sem o nome da coluna) do arquivo do excel convertido para o arquivo do excel modelo e depois salve o arquivo modelo.</p>
              <p>6° Passo: Clique no botão abaixo "Importar lista de medicamentos" e selecione o arquivo do EXCEL que você acabou de colar os dados (o arquivo modelo)</p>
            </b-card-text>
          </b-card>
        </b-collapse>
      </b-col>
    </b-row>

    <b-card>
      <b-row>
        <b-col lg="2" sm="6">
          <b-button variant="relief-success" v-b-modal.modal-store>
            Adicionar
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
        <b-col lg="2" sm="6">
          <b-button
            variant="relief-primary"
            v-b-modal.modal-import
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            title="Importar lista de medicamento"
            v-b-tooltip.hover.v-primary
          >Importar lista
            <feather-icon icon="ShareIcon" />
          </b-button>
        </b-col>
        <b-col lg="2" sm="6">
          <b-button
            variant="relief-info"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            title="Baixar modelo de importação"
            v-b-tooltip.hover.v-success
            href="https://visinonni-assets.s3.amazonaws.com/visinonni/ModeloMedicamentosVisiNonni.xlsx"
            download="modeloImportarMedicamento"
          >Baixar modelo
            <feather-icon icon="DownloadIcon" />
          </b-button>
        </b-col>
        <b-col>
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            size="sm"
            type="search"
          />
        </b-col>
      </b-row>
      <b-overlay :show="isLoadingMedicaments" opacity="0.70" rounded="sm">
        <b-table-simple
          striped
          stacked="md"
          class="mt-2 responsive text-center"
          id="tableMedicaments"
          :item="fetchMedicaments"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <b-thead>
            <b-tr class="text-center">
              <b-th>Medicamento</b-th>
              <b-th>Farmaco</b-th>
              <b-th>Concentração</b-th>
              <b-th>Forma Farmacêutica</b-th>
              <b-th>Data de registro</b-th>
              <b-th>Ação</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr :key="indextr" v-for="(tr, indextr) in medicaments">
              <b-td>
                {{ tr.name }}
              </b-td>
              <b-td>
                {{ tr.farmaco }}
              </b-td>
              <b-td>
                {{ tr.dosage }}
              </b-td>
              <b-td>
                {{ tr.amount_type.name }}
              </b-td>
              <b-td>{{ tr.created_at | dateFormat }}</b-td>
              <b-td>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-warning"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-update-medicament
                  @click="showDetailMedicament(tr.id)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                  v-b-modal.modal-delete-medicament
                  @click="showDetailMedicament(tr.id)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableMedicaments"
        class="mt-1"
      />
    </b-card>
    <b-modal
      id="modal-store"
      modal-class="modal-success"
      ref="modal-store"
      centered
      title="Cadastrar"
    >
      <b-card-text>
        <label> Nome </label>
        <b-form-input
          placeholder="Nome"
          v-model="dataMedicament.name"
          id="name"
        />
        <label> Concentração </label>
        <b-form-input
          placeholder="Concentração"
          v-model="dataMedicament.dosage"
          id="dosage"
        />
        <label>Forma Farmacêutica</label>
        <b-form-select
          text-field="name"
          value-field="id"
          v-model="dataMedicament.amount_type_id"
          :options="amountList"
          id="amount"
          size="sm"
        ></b-form-select>
        <label> Nome de Importação </label>
        <b-form-input placeholder="Nome de Importação" disabled id="dosage" />
      </b-card-text>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mr-1"
          @click="storeMedicaments"
        >
          Cadastrar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-import"
      modal-class="modal-primary"
      ref="modal-import"
      centered
      title="Cadastrar"
    >
      <b-card-text>
        <b-form-input class="hidden" name="_token" :value="csrf" />
        <label> Lista de Remedios </label>
        <b-form-file
          label="Arquivo Excel"
          type="file"
          @change="update_excel"
          v-model="excel"
          accept="file/*"
          name="url"
        />
      </b-card-text>
      <template #modal-footer>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="importMedicament"
        >
          Importar Lista
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-delete-medicament"
      ok-variant="danger"
      modal-class="modal-danger"
      ref="modal-delete-medicament"
      centered
      title="Deletar Quantidade"
    >
      <b-card-text>
        Você está preste a deletar este medicamento do sistema:
        <hr />
        <p><b>Nome: </b>{{ medicamentModel ? medicamentModel.name : "" }}</p>
        <p>
          <b>Concentração: </b
          >{{ medicamentModel ? medicamentModel.dosage : "" }}
        </p>
        <hr />
        Por favor, confirme a exclusão antes de prosseguir.
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
          unchecked-value="null"
        >
          Concordo em excluir este medicamento
        </b-form-checkbox>
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == null"
            @click="deleteMedicament(medicamentModel.id)"
          >
            Deletar
          </b-button>
        </b-col>
      </template>
    </b-modal>

    <b-modal
      id="modal-update-medicament"
      ok-variant="warning"
      modal-class="modal-warning"
      ref="modal-update-medicament"
      centered
      title="Editar Medicamento"
    >
      <b-card-text>
        Você está preste a editar este medicamento do sistema:
        <hr />
      </b-card-text>
      <div v-if="medicamentModel">
        <label class="mt-1"> Novo Nome </label>
        <b-form-input
          placeholder="Novo Nome"
          id="title"
          @change="newNameMedicament"
          :value="(updateMedicaments.name = medicamentModel.name)"
        >
          {{ medicamentModel.name }}
        </b-form-input>
        <label class="mt-1"> Nova Concentração </label>
        <b-form-input
          placeholder="Nova Concentração"
          id="title"
          @change="newDosageMedicament"
          :value="(updateMedicaments.dosage = medicamentModel.dosage)"
        >
          {{ medicamentModel.dosage }}
        </b-form-input>
        <label> Nome de Importação </label>
        <b-form-input
          placeholder="Nome de Importação"
          disabled
          :value="
            (updateMedicaments.imported_name =
              medicamentModel.amount_type.imported_name)
          "
          id="dosage"
        >
          {{ medicamentModel.amount_type.imported_name }}
        </b-form-input>
      </div>
      <div v-else>Medicamento não encontrada</div>
      <hr />
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-warning"
            block
            :value="(updateMedicaments.id = medicamentModel.id)"
            @click="updateMedicament"
          >
            Editar
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCard,
    BCardActions
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoadingMedicaments: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',

      medicamentModel: null,

      excel: null,

      DeleteStatus: null,

      dataMedicament: {
        name: null,
        dosage: null,
        amount_type_id: null,
      },

      dataExcel: {
        excel: null,
      },

      updateMedicaments: {
        id: null,
        name: null,
        dosage: null
      },

      updateAmounts: {
        id: null,
        name: 'qtd',
        imported_name: null,
        dosage: 'dosage'
      },
    };
  },
  computed: {
    ...mapState("amounts", ["amounts", "amountList"]),
    ...mapState("medicaments", ["medicaments"]),
  },
  methods: {
     ...mapActions("medicaments", ["store", "import"]),
     ...mapActions("amountList", ["fetchAmountList",]),

    newDeleteStatus(value){
      this.DeleteStatus = value;
    },

    // Medicaments

    fetchMedicaments(paginated = true, page = 1) {
      this.$store
        .dispatch("medicaments/fetchMedicaments", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },

    showDetailMedicament(id){
      this.medicamentModel = this.$store.getters['medicaments/getMedicamentsById'](id)
    },
    
    storeMedicaments() {
      this.store({
        ...this.dataMedicament,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchMedicaments() 
        this.$refs['modal-store'].hide()
      });
    },

    update_excel(e) {
      var files = e.target.files;
      this.dataExcel.excel =  files[0]
    },

    importMedicament() {
      this.import({
        ...this.dataExcel,
      })
      .then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Cadastrado com sucesso!',
            icon: 'ThumbsUpIcon',
            text: 'Operação executada com sucesso.',
            variant: 'success'
          },
        })   
      })
      .finally(() =>{
        this.fetchMedicaments() 
        this.$refs['modal-import'].hide()
      });
    },

    newNameMedicament(value){
      this.updateMedicaments.name = value;
    },

    newDosageMedicament(value){
      this.updateMedicaments.dosage = value;
    },

    updateMedicament() { 
      let data = {
        ...this.updateMedicaments,
      }; 
      this.$store.dispatch("medicaments/update", data)
      .then(() => { 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Atualizado com sucesso!',
            icon: 'Edit2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'warning'
          },
        })   
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchMedicaments() 
        this.$refs['modal-update-medicament'].hide()
      });
    },

    deleteMedicament(id) { 
      this.isLoadingMedicaments = true;
      let data = id; 
      this.$store.dispatch("medicaments/delete", data)
      .then(() => { 
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Excluído com sucesso!',
            icon: 'Trash2Icon',
            text: 'Operação executada com sucesso.',
            variant: 'danger'
          },
        }),
        this.isLoadingMedicaments = false;
        this.DeleteStatus = null
      })
      .catch(err => { 
        console.error(err); 
      })
      .finally(() =>{
        this.fetchMedicaments() 
        this.$refs['modal-delete-medicament'].hide()
        this.DeleteStatus = null
      });
    },

    handlePageChange(value) {
      this.fetchMedicaments(true, value);
    },

    // Amount

    fetchAmountList() {
      this.$store
        .dispatch("amounts/fetchAmountList")
        .then(() => {
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },
  },
  created() {
    this.fetchMedicaments();
    this.fetchAmountList();
    if (! this.csrf) {
      console.warn('The CSRF token is missing. Ensure that the HTML header includes the following: <meta name="csrf-token" content="{{ csrf_token() }}">');
    }
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchMedicaments(true, 1);
    },
    medicaments(){
      this.isLoadingMedicaments = false
    }

  },
};
</script>
